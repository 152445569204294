<script setup lang="ts"></script>

<template>
    <svg
        width="11"
        height="11"
        viewBox="0 0 11 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.5 8.55556C7.18754 8.55556 8.55556 7.18754 8.55556 5.5C8.55556 3.81246 7.18754 2.44444 5.5 2.44444C3.81246 2.44444 2.44444 3.81246 2.44444 5.5C2.44444 7.18754 3.81246 8.55556 5.5 8.55556ZM5.5 11C8.53757 11 11 8.53757 11 5.5C11 2.46243 8.53757 0 5.5 0C2.46243 0 0 2.46243 0 5.5C0 8.53757 2.46243 11 5.5 11Z"
            fill="currentColor"
        />
    </svg>
</template>
